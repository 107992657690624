<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-truck-delivery</v-icon> Entradas de producto</h1><br>
        <v-layout>
          <v-flex xs4>
            <v-select
              label="Finca"
              outlined
              v-model="finca"
              :items="fincasProductor"
              item-text="nombre"
              item-value="id"
              @change="filter"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <v-select
              label="Producto"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory; filter"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <v-select
              label="Calidad / categoría"
              outlined
              v-model="calidad"
              :items="calidades"
              item-text="nombre"
              item-value="nombre"
              @change="filter"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-menu
              v-model="menuDateDesde"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormattedDesde"
                  label="Desde"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaEntradaDesde'); read()"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInputDesde"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-menu
              v-model="menuDateHasta"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormattedHasta"
                  label="Hasta"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaEntradaHasta'); read()"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInputHasta"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Nº albarán"
              outlined
              v-model="albaran"
              @change="filter"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-btn class="btnCustom" @click="clear"><v-icon class="btnIcon">mdi-autorenew</v-icon> Limpiar filtros</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="showEntradas"
          show-expand
          item-key="albaran"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item.comentario }}
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Producto',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      selectClient: null,
      loading: false,
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      producto: null,
      repartos: ['Equitativo', 'Según superficie', 'Según plantas'],
      menuDateDesde: '',
      menuDateHasta: '',
      dateFormattedDesde: '',
      dateInputDesde: '',
      dateFormattedHasta: '',
      dateInputHasta: '',
      fincasProductor: [],
      finca: null,
      calidades: [],
      calidad: null,
      albaran: '',
      reparto: null,
      headers: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        { text: 'Finca', value: 'finca' },
        { text: 'Tipo producto', value: 'tipoproducto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Rendimiento', value: 'rendimiento' },
        { text: 'Acidez / Humedad', value: 'acidez' },
        { text: 'Albarán', value: 'albaran' },
        { text: '', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      entradas: [],
      showEntradas: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.dateInputDesde = basics.getDate("month", -1);
    this.dateFormattedDesde = basics.formatDate(this.dateInputDesde,"dd/mm/yyyy");
    this.dateInputHasta = basics.getDate();
    this.dateFormattedHasta = basics.formatDate(this.dateInputHasta,"dd/mm/yyyy");
    this.getFincas(); 
    this.read();
  },
  methods:{
    changeDateFormatted(field){
      switch(field){
        case "fechaEntradaDesde":
          if(this.dateInputDesde){
            this.dateFormattedDesde = basics.formatDate(this.dateInputDesde,"dd/mm/yyyy");
          }
          break;
        case "fechaEntradaHasta":
          if(this.dateInputHasta){
            this.dateFormattedHasta = basics.formatDate(this.dateInputHasta,"dd/mm/yyyy");
          }
          break;
      }
    },
    clear(){
      this.dateInputDesde = basics.getDate("month", -1),
      this.dateFormattedDesde = basics.formatDate(this.dateInputDesde,"dd/mm/yyyy"),
      this.dateInputHasta = basics.getDate(),
      this.dateFormattedHasta = basics.formatDate(this.dateInputHasta,"dd/mm/yyyy"),
      this.finca = null,
      this.calidad = null,
      this.albaran = '',
      this.producto = '',
      this.showEntradas = [];
      this.entradas = [];
      this.read();
    },
    async read(){
      this.entradas = [];
      const response = await genericReload(this.$store.state.auth, `entrada/?fechaInicio=`+this.dateInputDesde+`&fechaFin=`+this.dateInputHasta);
      if (response.status === 200) {
        const json = await response.json();
        for(let i = 0; i<json.body.length; i++){
          let getDataFinca = this.fincasProductor.find((f) => json.body[i].idFinca == f.id);
          let product = '';
          if(json.body[i].calidad){
            product = json.body[i].calidad;
          }else{
            if(json.body[i].categoria){
              product = json.body[i].categoria;
            }
          }
          this.entradas.push({
            id: json.body[i].id, 
            fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"), 
            finca: getDataFinca.nombre, 
            idFinca: json.body[i].idFinca, 
            tipoproducto: json.body[i].tipo,
            calidad: product,
            kilos: json.body[i].kilos,
            rendimiento: json.body[i].rendimiento,
            acidez: json.body[i].acidez,
            albaran: json.body[i].albaran,
            comentario: json.body[i].comentario,
          });
        }
        this.filter();
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de entradas anteriores", "Cerrar", "red");
      }
    },
    filter(){
      this.showEntradas = this.entradas;
      if(this.finca){
        this.showEntradas = this.entradas.filter((f) => f.idFinca == this.finca);
      }
      if(this.producto){
        this.showEntradas = this.entradas.filter((f) => f.tipoproducto == this.producto);
      }
      if(this.calidad){
        this.showEntradas = this.entradas.filter((f) => f.calidad == this.calidad);
      }
      if(this.albaran != ''){
        this.showEntradas = this.entradas.filter((f) => f.albaran == this.albaran);
      }
    },
    async getFincas(){
      this.fincasProductor = [];
      const response = await genericReload(this.$store.state.auth, `finca`);
      if (response.status === 200) {
        const json = await response.json();
        for(let i = 0; i<json.body.length; i++){
          this.fincasProductor.push({
            id: json.body[i].id, 
            nombre: json.body[i].nombre,
          });
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las fincas", "Cerrar", "red");
      }
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>