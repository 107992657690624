var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('h1',[_c('v-icon',{staticClass:"iconTitle"},[_vm._v("mdi-truck-delivery")]),_vm._v(" Entradas de producto")],1),_c('br'),_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{attrs:{"label":"Finca","outlined":"","items":_vm.fincasProductor,"item-text":"nombre","item-value":"id"},on:{"change":_vm.filter},model:{value:(_vm.finca),callback:function ($$v) {_vm.finca=$$v},expression:"finca"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{attrs:{"label":"Producto","outlined":"","items":_vm.productos,"item-text":"n","item-value":"v"},on:{"change":function($event){_vm.loadCategory; _vm.filter}},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{attrs:{"label":"Calidad / categoría","outlined":"","items":_vm.calidades,"item-text":"nombre","item-value":"nombre"},on:{"change":_vm.filter},model:{value:(_vm.calidad),callback:function ($$v) {_vm.calidad=$$v},expression:"calidad"}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs2":""}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"autocomplete":"off","label":"Desde","readonly":"","outlined":""},model:{value:(_vm.dateFormattedDesde),callback:function ($$v) {_vm.dateFormattedDesde=$$v},expression:"dateFormattedDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateDesde),callback:function ($$v) {_vm.menuDateDesde=$$v},expression:"menuDateDesde"}},[_c('v-date-picker',{attrs:{"locale":"es","first-day-of-week":1,"outlined":""},on:{"change":function($event){_vm.changeDateFormatted('fechaEntradaDesde'); _vm.read()}},model:{value:(_vm.dateInputDesde),callback:function ($$v) {_vm.dateInputDesde=$$v},expression:"dateInputDesde"}})],1)],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"autocomplete":"off","label":"Hasta","readonly":"","outlined":""},model:{value:(_vm.dateFormattedHasta),callback:function ($$v) {_vm.dateFormattedHasta=$$v},expression:"dateFormattedHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateHasta),callback:function ($$v) {_vm.menuDateHasta=$$v},expression:"menuDateHasta"}},[_c('v-date-picker',{attrs:{"locale":"es","first-day-of-week":1,"outlined":""},on:{"change":function($event){_vm.changeDateFormatted('fechaEntradaHasta'); _vm.read()}},model:{value:(_vm.dateInputHasta),callback:function ($$v) {_vm.dateInputHasta=$$v},expression:"dateInputHasta"}})],1)],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-text-field',{attrs:{"label":"Nº albarán","outlined":""},on:{"change":_vm.filter},model:{value:(_vm.albaran),callback:function ($$v) {_vm.albaran=$$v},expression:"albaran"}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{staticClass:"btnCustom",on:{"click":_vm.clear}},[_c('v-icon',{staticClass:"btnIcon"},[_vm._v("mdi-autorenew")]),_vm._v(" Limpiar filtros")],1)],1)],1)],1)],1),_c('v-layout',{staticStyle:{"margin-top":"20px"}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.showEntradas,"show-expand":"","item-key":"albaran"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.comentario)+" ")])]}}])})],1)],1),_c('showDialog',{ref:"dialog"}),_c('showMessage',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }